/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { lazy, useState, useRef } from 'react';
import { CardBody, Card, Row, Col, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai'
import { getRequest } from '../hooks/axiosClient';
import Sheet from 'react-modal-sheet';
import { IoMdSearch } from "react-icons/io";

const FindWayTabs = lazy(() => import('./FindWayTab'));
import('./FindWayTab');
let projectData
const localprojectData = localStorage.getItem('project_data');
if (localprojectData) {
    projectData = JSON.parse(localprojectData);
} 

const StartingPoint = ({
    open, setOpen,
    setCurrentFloor,
    setFromDestinationData,
    activeTab,
    setActiveTab
}) => {
    const navigate = useNavigate();
    const [tags, setTags] = useState([]);
    const [tagsTemp, setTagsTemp] = useState([]);
    const [searchTerm, setSearchTerm] = useState();
    const [searchTermMaintags, setSearchTermMaintags] = useState();
    const [pageCount, setPageCount] = useState(1);
    const [tagsFromCustom, setTagsFromCustom] = useState([]);
    const [scrollToBottom, setScrollToBottom] = useState(false);
    const [tagsData, setTagsData] = useState([]);

    const [disableDrag, setDisableDrag] = useState(false);

    const sheetRef = useRef(null);


    const close = () => {
        setPageCount(1);
        setTagsData([])
        setTagsTemp([]);
        setOpen(false);
        setSearchTermMaintags('')
        navigate(`/view-floorplan/0`)
    }

    const handleSearch = (event) => {
        const searchKey = event.target.value ?? null
        setSearchTermMaintags(searchKey)
        if (activeTab > 2) {
            setSearchTerm(searchKey);
            const filteredData = tags.filter((val) => {
                const {
                    floor_plan = '',
                    name = '',
                    type = '',
                    product_code = ''

                } = val;
                if (searchKey === '' || searchKey === ' ' || !searchKey) {
                    setTagsFromCustom([]);
                    setTimeout(() => {
                        setPageCount(0);
                    }, 1000);
                    // return val;
                }
                return (
                    floor_plan?.toLowerCase().includes(searchKey.toLowerCase()) ||
                    name?.toLowerCase().includes(searchKey.toLowerCase()) ||
                    type?.toLowerCase().includes(searchKey.toLowerCase()) ||
                    product_code?.toLowerCase().includes(searchKey.toLowerCase())

                );
            });
            setTagsTemp(filteredData)

        } else {
            const project = JSON.parse(localStorage.getItem('project_data'));
            const project_Id = project?.project_id
            let data = {
                project_id: project_Id,
                search_key: searchKey,
                page: '1',
            }
            if (searchKey) {
                setTagsTemp([])
                setTags([])
                getSearchData(data)
            } else {
                setTags([])
                setTagsTemp([]);
                setPageCount(1);
            }
        }

    }
    const verticaldata = (data) => {
        let result = [];
        data?.forEach((element) => {
            element?.transport_details?.forEach((item) => {
                const newObj = {
                    enc_id: element?.enc_id,
                    icon_name: element?.icon_name,
                    icon_id: element?.icon_id,
                    name: element?.name,
                    enc_floor_plan_id: item?.fp_id,
                    floor_plan: item?.floor_plan,
                    positions: item?.positions,
                    type: element?.type,
                    draft_id: item?.vtd_id
                };
                result.push(newObj);
            });
        });
        return result;
    };

    const getSearchData = async (value) => {
        setTagsTemp([])
        setTags([]);
        try {
            const reqUrl = activeTab == 3 ? `tags-search/${value?.project_id}/${value?.search_key}/${value?.page}` : `all-search/${value?.project_id}/${value?.search_key}/${value?.page}/${activeTab}`
            const response = await getRequest(reqUrl);
            let data = response.data.data ?? [];
            if (activeTab < 3) {
                var vertical = data?.filter((item) => item?.type == 6) ?? []
                var vercalTransport = verticaldata(vertical) ?? []
                var otherPins = data?.filter((item) => item?.type != 6) ?? [];
                data = otherPins.concat(vercalTransport ?? [])
            }
            if (activeTab == 3) {
                data?.forEach((element, index) => {
                    data[index] = { name: element };
                });
            }
            setTagsTemp(data ?? [])
            setTags(data ?? [])

        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    return (
        <div>
            <Sheet isOpen={open} onClose={() => close()} disableDrag={disableDrag} className='sheet-cont'
            >
                <Sheet.Container >
                    <Sheet.Content >
                        <Row justify="center" >
                            <Col xxl={24} lg={24} xs={24}>
                                <Card className='mb-3'>
                                    <CardBody>
                                        <Row>
                                            <Col sm={12} className="d-flex align-items-center align-end">
                                                <h5 className='header'>Starting Point</h5>
                                                <div className='close-icon' onClick={close}>
                                                    <AiOutlineClose />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col sm={12}>
                                                <div className='search-box'>
                                                    <IoMdSearch className='search-icon' />
                                                    <Input
                                                        placeholder="Search"
                                                        type="text"
                                                        className='serach-box'
                                                        onChange={handleSearch}
                                                        value={searchTermMaintags}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col sm={24}>
                                                <FindWayTabs
                                                    from='from'
                                                    tagsTemp={tagsTemp}
                                                    setTagsTemp={setTagsTemp}
                                                    setTags={setTags} tags={tags}
                                                    activeTab={activeTab}
                                                    setActiveTab={setActiveTab}
                                                    searchTerm={searchTerm}
                                                    searchTermMaintags={searchTermMaintags}
                                                    setPageCount={setPageCount}
                                                    pageCount={pageCount}
                                                    setSearchTermMaintags={setSearchTermMaintags}
                                                    tagsFromCustom={tagsFromCustom}
                                                    setTagsFromCustom={setTagsFromCustom}
                                                    setSearchTerm={setSearchTerm}
                                                    scrollToBottom={scrollToBottom}
                                                    setScrollToBottom={setScrollToBottom}
                                                    // handleScroll={handleScroll}
                                                    tagsData={tagsData}
                                                    setTagsData={setTagsData}
                                                    close={close}
                                                    setCurrentFloor={setCurrentFloor}
                                                    setFromDestinationData={setFromDestinationData}
                                                    disableDrag={() => setDisableDrag(true)}
                                                    enableDrag={() => setDisableDrag(false)}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>

        </div>
    );
}

export default StartingPoint;
