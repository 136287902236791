import React, { useState, useEffect, lazy, useRef } from "react";
import { CardBody, Card, Label, Row, Col, FormGroup, Button } from "reactstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Main } from "../Styles/styled";
import LogoContainer from "./LogoContainer";
import "./page.css";
import { getRequest, postRequest } from "../hooks/axiosClient";
import FloorsList from "./floorList";
import { environmentaldatas } from "../constant/defaultValues";
import FabricCanvas from "./FabricCanvas";
import toast, { Toaster } from "react-hot-toast";
import ReportIssue from "./ReportIssue";
import ImageSlider from "./ImageSlider";
import { encode } from "../helpers/utils";
import { rotateCanvas } from "../helpers/CanvasConstants.js/canvasFunctions";
import { fabric } from "fabric";
const StartingPoint = lazy(() => import("./StartingPoint"));
import("./StartingPoint");
const Destination = lazy(() => import("./Destination"));
import("./Destination");
const { image_url } = environmentaldatas;

function ViewFloorPLan() {
  const { id } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const [fromDestinationData, setFromDestinationData] = useState();
  const [toDestinationData, setToDestinationData] = useState();
  const [projectData, setProjectData] = useState([]);
  const [currentFloor, setCurrentFloor] = useState();
  const [canvas, setCanvas] = useState();
  const [canvasRotationAngle, setCanvasRotationAngle] = useState(0);
  const [openStarting, setOpenStarting] = useState(false);
  const [openDestination, setOpenDestination] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [openReport, setOpenReport] = useState(false);
  const [adDetails, setAdDetails] = useState([]);

  const istaskrendered = useRef(false)
  const prevValuesRef = useRef({
    id: null,
    openStarting: null,
    openDestination: null,
  });

  const fromClick = () => {
    setActiveTab("0");
    setTimeout(() => {
      setOpenStarting(true);
    }, 100);
  };

  const toClick = () => {
    setActiveTab("0");
    setTimeout(() => {
      setOpenDestination(true);
    }, 100);
  };

  const findMyWay = () => {
    const toData = JSON.parse(localStorage.getItem("to_location"));
    const fromData = JSON.parse(localStorage.getItem("from_location"));
    if (!fromDestinationData?.from_id && !toDestinationData?.to_id) {
      toast.error("Please choose the origin and destination.");
    } else if (!fromDestinationData?.from_id) {
      toast.error("Please choose an origin.");
    } else if (!toDestinationData?.to_id) {
      toast.error("Please choose a destination.");
    } else if (fromDestinationData?.from_id == toDestinationData?.to_id) {
      toast.error("Please choose different origin and destination.");
    } else {
      if (fromData?.type === "1" || fromData?.type === "2") {
        getInteraction(fromData);
      }
      if (toData?.type === "1" || toData?.type === "2") {
        getInteraction(toData);
      }
      if (
        fromDestinationData?.from_floor_plan_id ==
        toDestinationData?.to_floor_plan_id
      ) {
        navigate(`/show-destination/${1}`);
      } else {
        navigate(`/show-destination/${0}`);
      }
    }
  };

  const getInteraction = async (Data) => {
    const project = localStorage.getItem("project_data");
    const data = project ? JSON.parse(project) : null;
    const formData = {
      customer_id: data?.enc_customer_id,
      project_id: data?.enc_id,
      type: Data?.type,
      type_id: Data?.from_id ?? Data?.to_id,
    };
    try {
      const response = await postRequest(`pin-interactions`, formData);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false)
    }
  };

  const reportPage = () => {
    const project = localStorage.getItem("project_data");
    const data = project ? JSON.parse(project) : null;
    setOpenReport(true);
  };

  const getScanViewCount = async (projectID, BeaconID) => {
    let value = {
      project_id: projectID,
      beacon_id: BeaconID
    }
    try {
      const reqUrl = `total-views`;
      const response = postRequest(reqUrl, value);
      const currentUrl = window.location.href;
      localStorage.removeItem("share_url");
      localStorage.setItem("share_url", currentUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const getFloorList = async (value) => {
    try {
      const response = await getRequest(`levels/${value?.project_id}`);
      const data = response.data ?? [];
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    const prevValues = prevValuesRef.current;
    const isIdChanged = prevValues.id !== id;
    const isOpenStartingChanged = prevValues.openStarting !== openStarting;
    const isOpenDestinationChanged =
      prevValues.openDestination !== openDestination;
    console.log(id, 'openStarting')
    const toData = localStorage.getItem("to_location");

    if (isIdChanged || isOpenStartingChanged || isOpenDestinationChanged) {
      // setToDestinationData();
      const fromData = localStorage.getItem("from_location");
      if (id > 0 && id) {

        descriptionInfo(id, null, "from");
        localStorage.removeItem("to_location");
        console.log(id && fromData, "id&&fromData");
      } else if (id && fromData) {

        // const toData = localStorage.getItem("to_location");
        const data = JSON.parse(fromData);
        if (data?.type != 6) {
          descriptionInfo(data?.from_id, data?.type, "from");
        } else {
          // setFromDestinationData();
          setFromDestinationData(data);
          setCurrentFloor(data?.from_floor_plan_id);
        }
        if (toData) {
          const data = JSON.parse(toData);
          if (data?.type != 6) {
            descriptionInfo(data?.to_id, data?.type, "to");
          } else {
            let toDAta = {
              to: data?.from,
              to_draft_id: data?.from_draft_id,
              to_floor_plan: data?.from_floor_plan,
              to_type_name: data?.from_type_name,
              to_floor_plan_id: data?.from_floor_plan_id,
              to_id: data?.from_id,
              type: data?.type,
            };
            localStorage.setItem("to_location", JSON.stringify(toDAta));
            // console.log("is setting to data");
            setToDestinationData(toDAta);
          }
        }
      } else {
        console.log(
          JSON.parse(toData), " (isIdChanged  ,isOpenStartingChanged ,isOpenDestinationChanged)"
        );
        // localStorage.removeItem("to_location");
        const project = localStorage.getItem("project_data");
        const data = project ? JSON.parse(project) : null;
        getFloorList(data);
        const floorid = localStorage.getItem("floorId");
        setCurrentFloor(floorid);
        getAdvertisementsData(data?.project_id);
        // setToDestinationData();
        const datas = JSON.parse(fromData);
        if (datas?.type != 6) {
          descriptionInfo(datas?.from_id, datas?.type, "from");
          setToDestinationData(JSON.parse(toData))
        } else {
          // setFromDestinationData();
          setFromDestinationData(datas);
          setToDestinationData(JSON.parse(toData))
          setCurrentFloor(datas?.from_floor_plan_id);
        }
      }
    }
  }, [id, openStarting, openDestination, location]);

  const getProjectdata = async (id) => {
    try {
      const reqUrl = `project-data/${id}`;
      const response = await getRequest(reqUrl);
      const data = response.data?.project_data ?? [];
      if (data) {
        localStorage.removeItem("project_data");
        let values = {
          ...data,
          project_id: data?.enc_id,
          project_logo: data?.logo ? image_url + data?.logo : null,
        };
        if (values?.status == 1) {
          localStorage.setItem("project_data", JSON.stringify(values));
          setProjectData(values);
        } else {
          navigate(`/not-found/${encode(1)}`);
        }
      } else {
        navigate(`/not-found/${encode(1)}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const descriptionInfo = async (id, type, description) => {
    try {
      const reqUrl = type
        ? `destinations-info/${id}/${type}`
        : `destinations-info/${id}`;
      const response = await getRequest(reqUrl);
      console.log(response, "descriptionInfo");
      const data = response.data.data ?? [];
      if (description == "from") {
        let values = {
          from: data?.name,
          from_id: data?.enc_id,
          from_floor_plan: data?.floor_plan,
          project_id: data?.enc_project_id,
          from_floor_plan_id: data?.enc_floor_plan_id,
          customer_id: data?.enc_customer_id,
          from_draft_id: data?.draft_id,
          type: data?.type,
        };
        getProjectdata(values?.project_id);
        getAdvertisementsData(values?.project_id);
        if (type == null) {
          const dataToStore = {
            from: data?.name,
            from_id: data?.enc_id,
            from_floor_plan: data?.floor_plan,
            type: data?.type,
            from_floor_plan_id: data?.enc_floor_plan_id,
            from_type_name: "beacon",
            from_draft_id: data?.draft_id,
            customer_id: data?.enc_customer_id,
          };
          localStorage.setItem("from_location", JSON.stringify(dataToStore));
          getScanViewCount(data?.enc_project_id, id);
          setFromDestinationData(dataToStore);
        }
        // setFromDestinationData();
        setFromDestinationData(values);
        setCurrentFloor(data?.enc_floor_plan_id);
        localStorage.setItem("floorId", data?.enc_floor_plan_id);
      } else {
        let values = {
          to: data?.name,
          to_id: data?.enc_id,
          to_floor_plan: data?.floor_plan,
          project_id: data?.enc_project_id,
          to_floor_plan_id: data?.enc_floor_plan_id,
          customer_id: data?.enc_customer_id,
          to_draft_id: data?.draft_id,
          type: data?.type,
        };
        setToDestinationData(values);
      }
    } catch (error) {
      const errMSG = error.response?.data?.message
      if (errMSG == "Invalid id."){
        navigate(`/not-found/${encode(2)}`);
      }
        console.log(errMSG);
    } finally {
      // setLoading(false)
    }
  };

  function resetCanvasTransform() {
    canvas?.absolutePan({ x: 0, y: 0 });
    canvas?.setZoom(1);
  }

  const onSelectedFloor = (e) => {
    resetCanvasTransform();
    localStorage.setItem("floorId", e);
    setCurrentFloor(e);
    // resetCanvasTransform()
  };

  const getAdvertisementsData = async (id) => {
    try {
      const reqUrl = `advertisement-data/${id}`;
      const response = await getRequest(reqUrl);
      const data = response.data?.adv_data ?? [];
      setAdDetails(data);
    } catch (error) {
      console.log(error);
    }
  };


  const addApointToRotate = (canvas,midpoint) =>{
    let centerPoint;
    const transform = canvas.viewportTransform;
    if(midpoint){
      centerPoint= midpoint
    }else if (transform) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const topLeft = fabric.util.transformPoint({ x: 0, y: 0 }, fabric.util.invertTransform(transform));
      const bottomRight = fabric.util.transformPoint({ x: viewportWidth, y: viewportHeight }, fabric.util.invertTransform(transform));

      const visibleArea = {
        x1: topLeft.x,
        y1: topLeft.y,
        x2: bottomRight.x,
        y2: bottomRight.y,
      };
      const xCenter = (visibleArea.x1 + visibleArea.x2) / 2;
      const yCenter = (visibleArea.y1 + visibleArea.y2) / 2;
      centerPoint = {
        x: xCenter,
        y:yCenter
      }

    }
    const circle = new fabric.Circle({
      left:centerPoint.x,
      top:centerPoint.y,
      radius: 5,
      fill: "rgba(0,0,0,0)",
      // fill: "red",
      originX: "center",
      originY: "center",
      selectable:false,
      types:"centerPointPin",
      name:"centerPointPin"
    });
  // Remove existing visible area rectangles before adding a new one
  const existingCircle = canvas.getObjects('circle').filter(obj => obj.fill === 'rgba(0,0,0,0)');
  existingCircle.forEach(cir => canvas.remove(cir));

  canvas.add(circle);
  canvas.renderAll();
  }

  //destination swap handler
  const handleDestinationSwap = () => {
    let storageToData = JSON.parse(localStorage.getItem("to_location"));
    let storageFromData = JSON.parse(localStorage.getItem("from_location"));
    const toData = storageToData ? storageToData : toDestinationData;
    const fromData = storageFromData ? storageFromData : fromDestinationData;
    console.log(fromData, 'fromDatafromData')
    let destinationFrom = {
      from: toData?.to,
      from_id: toData?.to_id,
      from_floor_plan: toData?.to_floor_plan,
      type: toData?.type,
      from_floor_plan_id: toData?.to_floor_plan_id,
      from_type_name: toData?.to_type_name,
      from_draft_id: toData?.to_draft_id,
      project_id: toData?.project_id,
      customer_id: toData?.customer_id,
    };

    let destinationTo = {
      to: fromData?.from,
      to_id: fromData?.from_id,
      to_floor_plan: fromData?.from_floor_plan,
      project_id: fromData?.project_id,
      to_type_name: fromData?.from_type_name,
      to_floor_plan_id: fromData?.from_floor_plan_id,
      customer_id: fromData?.customer_id,
      to_draft_id: fromData?.from_draft_id,
      type: fromData?.type,
    };

    setFromDestinationData(destinationFrom);
    setCurrentFloor(destinationFrom?.from_floor_plan_id);
    console.log(toData, fromData, "toData, fromData");
    setToDestinationData(destinationTo);

    localStorage.setItem("from_location", JSON.stringify(destinationFrom));
    localStorage.setItem("to_location", JSON.stringify(destinationTo));
  };

  const canvasRotationClick = () => {
    console.log(istaskrendered,' istaskrenderedistaskrenderedistaskrenderedistaskrendered')
    if(istaskrendered.current){
      let highlight_pin = null
      let centerPointPin = null
      canvas?.forEachObject((obj) => {
        if (obj?.types === "centerPointPin") {
          centerPointPin = obj
        }else if (obj?.types === "highlight_pin") {
          highlight_pin = obj
        }
      });
  
      let point
      if(centerPointPin){
        point = {
          x: centerPointPin.left,
          y: centerPointPin.top
        }
        rotateCanvas(30,canvas,point)
      }else if(highlight_pin){
        point = {
          x: highlight_pin.left,
          y: highlight_pin.top
        }
        rotateCanvas(30,canvas,point)
      }
    }
  }


  const returnToVehicle = async () =>{
    let endingpoint = localStorage.getItem("share_url")?.split("/")
    try {
        const response = await getRequest(`destinations-info/${endingpoint[endingpoint.length - 1]}`);
        const data = response.data.data ?? [];
          let to = {
            to: data?.name,
            to_id: data?.enc_id,
            to_floor_plan: data?.floor_plan,
            type: data?.type,
            to_floor_plan_id: data?.enc_floor_plan_id,
            to_draft_id: data?.draft_id,
            project_id: data?.enc_project_id,
            customer_id: data?.enc_customer_id,
            to_type_name: data?.type == 3 ? 'beacon' : data?.type == 4 ? 'amenity' : data?.type == 5 ? 'safety' : data?.type == 6 ? 'vertical' : undefined,
          }
        // console.log(dataToStore,"dataToStore");
        if(to){
          setToDestinationData(to)
          localStorage.setItem("to_location", JSON.stringify(to));
          fromClick()
        }
        
    } catch (error) {
        console.log(error);
    } 
  }

  return (
    <>
      {adDetails?.length > 0 && (
        <ImageSlider
          adDetails={adDetails}
          setCurrentFloor={setCurrentFloor}
          setFromDestinationData={setFromDestinationData}
        />
      )}
      <Main className="main-cont">
        <LogoContainer projectData={projectData} adDetails={adDetails} />

        <FloorsList
          values={currentFloor}
          onchange={onSelectedFloor}
          adDetails={adDetails}
        />

        <FabricCanvas
          currentFloor={currentFloor}
          from={fromDestinationData ?? null}
          to={toDestinationData ?? null}
          canvas={canvas}
          setCanvas={setCanvas}
          setFromDestinationData={setFromDestinationData}
          setCurrentFloor={setCurrentFloor}
          addApointToRotate={addApointToRotate}
          istaskrendered={istaskrendered}
        />

        {/* <div className="canvas-orientation">
          <button onClick={canvasRotationClick}>
            <svg width={"32px"} height={"40px"} viewBox="0 0 100 100">
              <path d="M66.07,35.22a1.5,1.5,0,0,1-1.5-1.5v-12a1.5,1.5,0,0,1,1.5-1.5h12a1.5,1.5,0,0,1,0,3H67.57v10.5A1.5,1.5,0,0,1,66.07,35.22Z" />
              <path d="M33.93,79.78h-12a1.5,1.5,0,0,1,0-3h10.5V66.28a1.5,1.5,0,0,1,3,0v12A1.5,1.5,0,0,1,33.93,79.78Z" />
              <path d="M50,67.15a17.15,17.15,0,1,1,12.13-5A17.1,17.1,0,0,1,50,67.15Zm0-31.3A14.15,14.15,0,1,0,60,40,14.11,14.11,0,0,0,50,35.85Z" />
              <path d="M33.93,79.78a1.5,1.5,0,0,1-1.06-.44L18.73,65.2a21.5,21.5,0,0,1,0-30.41l8-8a1.5,1.5,0,0,1,2.12,2.12l-8,8a18.52,18.52,0,0,0,0,26.16L35,77.22a1.5,1.5,0,0,1-1.06,2.56Z" />
              <path d="M72.18,73.68a1.5,1.5,0,0,1-1.06-2.56l8-8a18.52,18.52,0,0,0,0-26.16L65,22.78a1.5,1.5,0,0,1,2.12-2.12L81.27,34.8a21.5,21.5,0,0,1,0,30.41l-8,8A1.5,1.5,0,0,1,72.18,73.68Z" />
            </svg>
          </button>
        </div> */}

        <Card className="bottom-card">
          <div className="canvas-orientation" >
            <button  onClick={returnToVehicle}>
              {/* <svg class="svg-icon" style={{width: "2em", height: "2em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M363.6 581.1m-43.8 0a43.8 43.8 0 1 0 87.6 0 43.8 43.8 0 1 0-87.6 0Z"  />
                <path d="M486.7 366.3c0-20.9 16.5-37.8 36.8-37.8 20.3 0 36.8 18.2 36.8 39.1 0 20.9-16.5 36.6-36.8 36.6-20.3-0.1-36.8-17.1-36.8-37.9zM524.7 454.6l33.7-41.1c-11-1.5-25.9-2.3-35-2.3-11.8 0-33.7 1.4-43.7 3.8-4.1 1-7.7 3.4-10.6 6.5L449 456.2l-8.4 9.5c-5.1 5.7-5.1 15 0 20.7l10 11.3h18.6c0-23.8 24.3-43.2 54.1-43.2 0.6 0 1 0.1 1.4 0.1zM606.2 465.8l-8.4-9.5-20.1-34.7c-2.1-2.3-4.7-4-7.6-5.3l-32.7 39.8c23 5 40.1 21.6 40.1 41.6h18.6l10-11.3c5.2-5.6 5.2-14.9 0.1-20.6zM284 653.6c-8.1 12.6-12.8 27.7-12.8 43.8 0 45 36.5 81.5 81.5 81.5s81.5-36.5 81.5-81.5c0-16.1-4.7-31.1-12.8-43.8H284z"  /><path d="M570 647.5H272.2c-7.1 0-13.6-2.9-18.4-8.1-4.8-5.2-7.1-12-6.5-19l6.9-82.8c1.1-12.9 12-22.9 24.9-22.9h317c19.5-30.6 47.6-55.1 81-70h-398c-23.9 0-46.7 8.9-64.3 25.1-17.6 16.2-28.4 38.2-30.4 62l-6.9 82.8c-2.2 26.4 6.8 52.8 24.8 72.3 17.9 19.5 43.4 30.7 70 30.7h326.1c-14-20.8-23.8-44.5-28.4-70.1z"  /><path d="M689.3 439.7H336.9l19-101.6 0.1-1.1c1.5-12.5 12.2-22 24.8-22h280.6c12.5 0 23.2 9.4 24.8 21.8l0.1 1.1 18.6 96.8c15.4-4.1 31.5-6.3 48.2-6.3 7.5 0 14.9 0.5 22.2 1.3l-19.8-103c-6.6-46.7-46.8-81.7-94.1-81.7H380.9c-47.5 0-87.8 35.3-94.2 82.2l-34.1 182.5h346.8c21.7-31.8 53.1-56.6 89.9-70z"  /><path d="M812.2 635.8H690.1c-19.3 0-35-15.7-35-35s15.7-35 35-35h122.1c19.3 0 35 15.7 35 35s-15.6 35-35 35z"  /><path d="M714.8 727.6c-9 0-17.9-3.4-24.8-10.3l-84-84c-17.9-17.9-17.9-47.1 0-65l84-84c13.7-13.7 35.8-13.7 49.5 0 13.7 13.7 13.7 35.8 0 49.5l-67 67 67 67c13.7 13.7 13.7 35.8 0 49.5-6.7 6.9-15.7 10.3-24.7 10.3z"  />
              </svg> */}
              <img alt="live location image" src="/live-location.jpg"/>
            </button>
          </div>
          <Row className=" bottom-row">
            <Col sm={12}>
              <Card className="custom-card">
                <div className="card-body-container">
                  <CardBody className="p-0 custom-cardbody">
                    <Row>
                      <Col sm={12}>
                        <FormGroup row className="p1-20 mb-0 ">
                          <Col
                            className="d-flex align-items-center "
                            sm={12}
                            xxs={12}
                            onClick={fromClick}
                          >
                            <Label for="exampleEmail" className="label-width">
                              From:
                            </Label>
                            <span className="location-value">
                              {fromDestinationData?.from}
                            </span>
                          </Col>
                        </FormGroup>
                        <Row row className="d-flex">
                          <Col sm={12} xxs={12}>
                            <hr className="mt-0 mb-0" />
                          </Col>
                        </Row>
                        <Row className="p2-20">
                          <Col
                            className="d-flex align-items-center "
                            sm={12}
                            xxs={12}
                            onClick={toClick}
                          >
                            <Label for="exampleEmail" className="label-width">
                              To:
                            </Label>
                            <span className="location-value">
                              {toDestinationData?.to}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                  <div>
                    <svg
                      onClick={handleDestinationSwap}
                      fill="#666666"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      className="swap-arrow"
                    >
                      <g data-name="Layer 2">
                        <g data-name="swap">
                          <rect
                            width="24"
                            height="24"
                            transform="rotate(-90 12 12)"
                            opacity="0"
                          />

                          <path d="M4 9h13l-1.6 1.2a1 1 0 0 0-.2 1.4 1 1 0 0 0 .8.4 1 1 0 0 0 .6-.2l4-3a1 1 0 0 0 0-1.59l-3.86-3a1 1 0 0 0-1.23 1.58L17.08 7H4a1 1 0 0 0 0 2z" />

                          <path d="M20 16H7l1.6-1.2a1 1 0 0 0-1.2-1.6l-4 3a1 1 0 0 0 0 1.59l3.86 3a1 1 0 0 0 .61.21 1 1 0 0 0 .79-.39 1 1 0 0 0-.17-1.4L6.92 18H20a1 1 0 0 0 0-2z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <StartingPoint
            open={openStarting}
            setOpen={setOpenStarting}
            setCurrentFloor={setCurrentFloor}
            setFromDestinationData={setFromDestinationData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <Destination
            open={openDestination}
            setOpen={setOpenDestination}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setCurrentFloor={setCurrentFloor}
            click={descriptionInfo}
          />
          <Row style={{ paddingLeft: "13px", marginTop: "5px" }}>
            <Col sm={12}>
              <div className="d-flex">
                <Button
                  onClick={() => findMyWay()}
                  size="small"
                  key="4"
                  className="btn btn-primary"
                  block
                >
                  Find my way
                </Button>
                <Button
                  className="float-right btn-icon btn btn-primary custom-button"
                  size="small"
                  type="light"
                  onClick={reportPage}
                  style={{ marginRight: "10px" }}
                >
                  <MdOutlineMoreHoriz className="button-option-icon" />
                </Button>
              </div>
            </Col>
          </Row>
          <ReportIssue
            setOpen={setOpenReport}
            open={openReport}
            customer_id={projectData?.enc_customer_id}
          />
        </Card>
      </Main>
    </>
  );
}

export default ViewFloorPLan;
