import { fabric } from "fabric";
import { escapeSpecialCharacters } from "../escapeSpecialCharacters";
import {
  ChangeSvgColorPassingBE,
  getSquareCoordinates,
} from "./canvasFunctions";
import { hexToRgb } from "../hextoRgb";

const renderTracings = (canvas, projectSettings, tracings) => {
  const tracingData = tracings?.length > 0 ? JSON.parse(tracings) : [];
  tracingData?.reverse()?.forEach((t, idx) => {
    console.log(t?.border_color,t?.border_thick,'t?.border_color')
    let strokeColor = t?.border_color ?? projectSettings?.border_color;
    let strokeWidth = t?.border_thick ?? 0;
    let fillColor = t?.fill_color ?? projectSettings?.fill_color;
    let polyObj = new fabric.Polygon(t.vertices, {
      objectCaching: false,
      name: "tracing",
      id: new Date().toString(),
      fill: fillColor,
      stroke: strokeColor,
      strokeWidth: Number(strokeWidth),
      originX: "center",
      originY: "center",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
    });
    canvas?.add(polyObj).renderAll();
    canvas.sendToBack(polyObj);
  });
};

const renderTracingCircle = (canvas, projectSettings, tracings) => {
  const tracingData = tracings?.length > 0 ? JSON.parse(tracings) : [];
  tracingData?.reverse()?.forEach((t, idx) => {
    let strokeColor = t?.border_color ?? projectSettings?.border_color;
    let strokeWidth = t?.border_thick ?? 0;
    let fillColor = t?.fill_color ?? projectSettings?.fill_color;

    let circle = new fabric.Circle({
      objectCaching: false,
      name: "tracing",
      id: new Date().toString(),
      fill: fillColor,
      stroke: strokeColor,
      strokeWidth: strokeWidth,
      left: t.vertices.x,
      top: t.vertices.y,
      scaleX: t.scaleX,
      scaleY: t.scaleY,
      radius: t.radius,
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
    });
    circle.setCoords();
    canvas?.add(circle);
    canvas.renderAll();
    canvas.sendToBack(circle);
  });
};

const renderTexts = (canvas, texts) => {
  const textData = texts?.length > 0 ? JSON.parse(texts) : [];
  textData?.forEach((item, idx) => {
    let textObj = new fabric.Text(item.text, {
      ignoreZoom: true,
      objectCaching: false,
      id: new Date().toString(),
      left: item.left,
      top: item.top,
      // fontSize: 14,
      name: "text",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      scaleX: item?.scaleX ?? 1,
      scaleY: item?.scaleY ?? 1,
      selectable: false,
      angle: item?.angle ?? 0,
      fontFamily: item.fontFamily ?? "Arial",
      fill: item.fill ?? "black",
      skipAbsolute: true,
      textAlign: item.textAlign ?? "center",
      fontSize: item.fontSize ?? "14",
      fontWeight: item.fontWeight ?? "normal",
    });
    if (item.height && item.width) {
      textObj.set({
        height: item.height,
        width: item.width,
      });
    }

    canvas?.add(textObj).renderAll();
    canvas?.bringToFront(textObj);
  });
};

const renderBeacons = (beacons, projectSettings, canvas, clickhandler) => {
  beacons.forEach((item, idx) => {
    let fillColor =
      projectSettings?.inactive_color ??
      item?.beacon_color ??
      projectSettings?.beacon_color;
    let singleLineBeaconName = escapeSpecialCharacters(item.beacon_name);
    let textObj = new fabric.Text(item.beacon_name, {
      left: item.position?.x,
      top: item.position?.y - 22,
      fill: "#646464",
      fontSize: 12,
      name: "beacon",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
      originX: "center",
      originY: "center",
      fontFamily: `'Arial'`,
      initialTop: item.position?.y - 22,
      initialLeft: item.position?.x,
      initialZoom: 1,
    });
    let beaconIcon = `<svg width="${
      textObj.width + 5 + 5
    }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">
        <g transform="translate(${textObj.width / 2 - 5}, 0)">
         <title>Layer 1</title>
         <title>Layer 1</title>
         <path id="svg_1" fill="${fillColor}" d="m9.95319,0.04415c-0.31035,-0.02209 -0.62066,-0.04415 -0.931,-0.04415c-0.93104,0 -1.83988,0.1325 -2.68225,0.39753c-0.84236,0.26503 -1.64041,0.64051 -2.37194,1.1264c-2.39409,1.61227 -3.968,4.3509 -3.968,7.46502c0,0.35337 0.02216,0.70674 0.0665,1.06015c0,0 0,0.0442 0,0.0662c0.04434,0.3313 0.11088,0.6847 0.17738,1.016c0.0665,0.2871 0.15516,0.5963 0.266,0.8834c0.04433,0.1105 0.08866,0.243 0.133,0.3534c0.02216,0.0662 0.06652,0.1325 0.08868,0.2209c1.39656,3.1803 4.56647,5.411 8.26844,5.411c3.702,0 6.8941,-2.2307 8.2685,-5.411c0.0222,-0.0663 0.0665,-0.1326 0.0887,-0.2209c0.0443,-0.1104 0.0887,-0.2429 0.133,-0.3534c0.0887,-0.2871 0.1995,-0.5742 0.266,-0.8834c0.0887,-0.3313 0.133,-0.6626 0.1773,-0.9939c0,0 0,-0.0441 0,-0.0662c0.0443,-0.35341 0.0665,-0.70677 0.0665,-1.06015c0,-0.3092 0,-0.61843 -0.0443,-0.92763c-0.4212,-4.2405 -3.8128,-7.59753 -8.04682,-8.01717l0.04431,-0.0221z"/>
         <path id="svg_2" fill="white" d="m9.02202,16.5206c4.17478,0 7.55908,-3.3719 7.55908,-7.53129c0,-4.15942 -3.3843,-7.5313 -7.55908,-7.5313c-4.17479,0 -7.55913,3.37188 -7.55913,7.5313c0,4.15939 3.38434,7.53129 7.55913,7.53129z"/>
         <path id="svg_3" fill="${fillColor}" d="m9.0223,15.1733c3.428,0 6.2069,-2.7687 6.2069,-6.18406c0,-3.41536 -2.7789,-6.18406 -6.2069,-6.18406c-3.42797,0 -6.20687,2.7687 -6.20687,6.18406c0,3.41536 2.7789,6.18406 6.20687,6.18406z"/>
         <path id="svg_4" fill="white" d="m11.8594,7.24421c1.1386,0 2.0616,-0.91962 2.0616,-2.05401c0,-1.13438 -0.923,-2.05397 -2.0616,-2.05397c-1.1386,0 -2.06155,0.91959 -2.06155,2.05397c0,1.13439 0.92295,2.05401 2.06155,2.05401z"/>
        </g>
        <g>
        <!-- Rectangle with border -->
        <rect x="5" y="-26" width="${
          textObj.width + 5
        }" height="20" rx="2" fill="#ffffffad" stroke="transparent" stroke-width="1"/>
        
        <!-- Text -->
        <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineBeaconName}</text>
      </g>
       </svg>`;

    let path = fabric.loadSVGFromString(
      beaconIcon,
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);
        let backgroundRect = new fabric.Rect({
          ignoreZoom: true,
          left: item.position?.x,
          top: item.position?.y - 22,
          width: textObj.width + 12,
          height: textObj.height + 5,
          fill: "#ffffffad",
          strokeWidth: 1,
          originX: "center",
          originY: "center",
          selectable: false,
          name: "beacon",
          rx: 2,
          ry: 2,
          skipAbsolute: true,
          initialTop: item.position?.y - 22,
          initialLeft: item.position?.x,
          initialZoom: 1,
        });
        obj.set({
          left: item.position?.x - obj.width / 2,
          top: item.position?.y - obj.height / 2,
          selectable: false,
          name: "beacon",
          id: item.beacon_name,
          enc_id: item?.enc_id,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          icon_color: fillColor,
          draft_id: item?.beacon_id,
          position: item?.position,
          initialColor: fillColor,
          color: item?.beacon_color,
          item_type: item?.type,
        });
        let group = new fabric.Group([backgroundRect, textObj], {
          name: "beacon",
          selectable: false,
          centeredScaling: true,
        });
        canvas.add(obj).renderAll();
        canvas.bringToFront(obj);
        // added by savad
        obj.on("mousedown", (event) => {
          // if (event.touches.length === 1) {
            // clickhandler(event,item);
          // }
        });
      }
    );
  });
};

const renderLocations = (locations, projectSettings, canvas, clickhandler) => {
  locations?.forEach((loc, idx) => {
    let vertices;
    if (loc.boundary_attributes && loc.boundary_attributes != "null") {
      vertices = JSON.parse(loc.boundary_attributes);
    } else if (loc?.boundary_attributes === "null" && loc?.boundary_color) {
      vertices = getSquareCoordinates(loc?.position?.x, loc?.position?.y, 50);
    }
    if (loc.boundary_color) {
      let polyObj = new fabric.Polygon(vertices, {
        name: "boundary",
        objectCaching: false,
        id: `boundary_${loc.location_name}`,
        fill: loc.boundary_color ? hexToRgb(loc.boundary_color) : null,
        stroke: "grey",
        strokeWidth: Number(0),
        originX: "center",
        originY: "center",
        selectable: false,
        lockScalingX: true,
        lockScalingY: true,
        ignoreZoom: true,
        skipAbsolute: true,
        opacity: 0,
        draft_id: loc?.location_id,
        enc_id: loc?.enc_id,
      });
      canvas.add(polyObj).renderAll();
      canvas.bringToFront(polyObj);
    }

    let fillColor =
      projectSettings?.inactive_color ??
      loc?.location_color ??
      projectSettings?.location_color;
    let singleLineLocationName = escapeSpecialCharacters(loc.location_name);
    let textObj = new fabric.Text(loc.location_name, {
      left: loc.position?.x,
      top: loc.position?.y - 25,
      fill: "#646464",
      fontSize: 12,
      name: "location",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
      originX: "center",
      originY: "center",
      fontFamily: `Arial`,
      ignoreZoom: true,
      skipAbsolute: true,
      initialTop: loc.position?.y - 25,
      initialLeft: loc.position?.x,
      initialZoom: 1,
    });
    let locationIcon = `<svg width="${
      textObj.width + 5 + 5
    }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">

        <g transform="translate(${textObj.width / 2 - 5}, 0)">
         <title>Layer 1</title>
         <path id="svg_1" fill="${fillColor}" d="m10,0c-5.53061,0 -10,4.32465 -10,9.67615c0,1.56005 0.42857,3.08055 1.08163,4.44315c0.46939,0.9873 1.55102,2.4684 1.81633,2.8238c1.53061,2.0142 7.10204,8.0569 7.10204,8.0569c0,0 5.5918,-6.0427 7.102,-8.0569c0.2653,-0.3554 1.347,-1.8167 1.8164,-2.8238c0.653,-1.3626 1.0816,-2.8831 1.0816,-4.44315c0,-5.3515 -4.4694,-9.67615 -10,-9.67615z"/>
         <path id="svg_2" fill="white" d="m9.99998,17.7923c4.63242,0 8.38772,-3.6337 8.38772,-8.11613c0,-4.4824 -3.7553,-8.11611 -8.38772,-8.11611c-4.63242,0 -8.38775,3.63371 -8.38775,8.11611c0,4.48243 3.75533,8.11613 8.38775,8.11613z"/>
         <path id="svg_3" fill="${fillColor}" d="m10,16.331c3.7984,0 6.8776,-2.9795 6.8776,-6.65486c0,-3.67535 -3.0792,-6.65482 -6.8776,-6.65482c-3.79836,0 -6.87755,2.97947 -6.87755,6.65482c0,3.67536 3.07919,6.65486 6.87755,6.65486z"/>
         <path id="svg_4" fill="white" d="m13.1429,7.80014c1.2623,0 2.2857,-0.99021 2.2857,-2.21169c0,-1.22148 -1.0234,-2.2117 -2.2857,-2.2117c-1.2624,0 -2.2858,0.99022 -2.2858,2.2117c0,1.22148 1.0234,2.21169 2.2858,2.21169z"/>
        </g>
        <g>
        <!-- Rectangle with border -->
        <rect x="5" y="-26" width="${
          textObj.width + 5
        }" height="20" rx="2" fill="#ffffffad" stroke="transparent" stroke-width="1"/>
        
        <!-- Text -->
        <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineLocationName}</text>
      </g>
       </svg>`;

    let square;
    let path = fabric.loadSVGFromString(
      locationIcon,
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);
        let currentZoom = canvas.getZoom();

        let backgroundRect = new fabric.Rect({
          left: loc.position?.x,
          top: loc.position?.y - 25,
          width: textObj.width + 10,
          height: textObj.height + 5,
          fill: "#ffffffad",
          stroke: "transparent",
          strokeWidth: 1,
          originX: "center",
          originY: "center",
          selectable: false,
          name: "location",
          rx: 2,
          ry: 2,
          ignoreZoom: true,
          skipAbsolute: true,
          initialTop: loc.position?.y - 25,
          initialLeft: loc.position?.x,
          initialZoom: 1,
        });
        let group = new fabric.Group([backgroundRect, textObj], {
          name: "location",
          selectable: false,
          ignoreZoom: true,
          skipAbsolute: true,
          centeredScaling: true,
        });
        obj.set({
          id: loc.location_name,
          left: loc.position?.x - obj?.width / 2,
          top: loc.position?.y - obj?.height / 2,
          selectable: false,
          name: "location",
          enc_id: loc?.enc_id,
          uniformScaling: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          isBoundary: loc?.boundary_attributes ? true : false,
          draft_id: loc?.location_id,
          position: loc?.position,
          ignoreZoom: true,
          skipAbsolute: true,
          boundary_color: loc?.boundary_color ?? null,
          boundary_attributes: vertices ?? null,
          color: loc?.location_color,
          item_type: loc?.type,
          initialColor: fillColor,
        });

        canvas.add(obj).renderAll();
        canvas.bringToFront(obj);
        // added by savad
        obj.on("mousedown", (event) => {
          // if (event.touches.length === 1) {
          // }
          console.log(event,event.touches)
          // clickhandler(event,loc);
        });
        
      }
    );
  });
};

const renderProducts = (products, from, to, canvas, projectSettings) => {
  products.forEach((prod, idx) => {
    let fillColor =
      "#b2b2b2" ?? prod?.product_color ?? projectSettings?.product_color;
    let singleLineProductName = escapeSpecialCharacters(prod.product_name);
    let textObj = new fabric.Text(prod.product_name, {
      left: prod.position?.x,
      top: prod.position?.y - 25,
      fill: "#646464",
      fontSize: 12,
      name: "product",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
      originX: "center",
      originY: "center",
      fontFamily: `Arial`,
      initialTop: prod.position?.y - 25,
      initialLeft: prod.position?.x,
      initialZoom: 1,
    });

    let productIcon = `<svg width="${
      textObj.width + 5 + 5
    }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">
        <g transform="translate(${textObj.width / 2 - 5}, 0)">
         <title>Layer 1</title>
         <path id="svg_1" fill="${fillColor}" d="m10,0c-5.52783,0 -10,4.32764 -10,9.67682c0,1.56018 0.42227,3.08318 1.07486,4.43908c0.47985,1.003 1.5547,2.4703 1.82341,2.8232c1.51632,2.0245 7.10173,8.0609 7.10173,8.0609c0,0 5.5854,-6.0364 7.1017,-8.0609c0.2687,-0.3529 1.3436,-1.8202 1.8234,-2.8232c0.6526,-1.3559 1.0749,-2.8789 1.0749,-4.43908c0,-5.34918 -4.4722,-9.67682 -10,-9.67682z"/>
         <path id="svg_2" fill="white" d="m9.99999,17.7935c4.63241,0 8.38771,-3.634 8.38771,-8.11669c0,-4.48269 -3.7553,-8.11664 -8.38771,-8.11664c-4.63241,0 -8.38772,3.63395 -8.38772,8.11664c0,4.48269 3.75531,8.11669 8.38772,8.11669z"/>
         <path id="svg_3" fill="${fillColor}" d="m10,16.3262c3.795,0 6.8714,-2.9771 6.8714,-6.64938c0,-3.67232 -3.0764,-6.64933 -6.8714,-6.64933c-3.79497,0 -6.8714,2.97701 -6.8714,6.64933c0,3.67228 3.07643,6.64938 6.8714,6.64938z"/>
         <path id="svg_4" fill="white" d="m10,11.9057c1.2721,0 2.3033,-0.9979 2.3033,-2.22888c0,-1.23094 -1.0312,-2.22882 -2.3033,-2.22882c-1.27206,0 -2.30327,0.99788 -2.30327,2.22882c0,1.23098 1.03121,2.22888 2.30327,2.22888z"/>
        </g>
        <g>
        <!-- Rectangle with border -->
        <rect x="5" y="-26" width="${
          textObj.width + 5
        }" height="20" rx="2" fill="#ffffffad" stroke="transparent" stroke-width="1"/>
        
        <!-- Text -->
        <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineProductName}</text>
      </g>
       </svg>`;

    let path = fabric.loadSVGFromString(
      productIcon,
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);

        let backgroundRect = new fabric.Rect({
          ignoreZoom: true,
          left: prod.position?.x,
          top: prod.position?.y - 25,
          width: textObj.width + 12,
          height: textObj.height + 5,
          fill: "#ffffffad",
          strokeWidth: 1,
          originX: "center",
          originY: "center",
          selectable: false,
          name: "product",
          rx: 2,
          ry: 2,

          initialTop: prod.position?.y - 25,
          initialLeft: prod.position?.x,
          initialZoom: 1,
        });
        let group = new fabric.Group([backgroundRect, textObj], {
          name: "product",
          selectable: false,
          centeredScaling: true,
        });

        obj.set({
          left: prod.position?.x - obj.width / 2,
          top: prod.position?.y - obj.height / 2,
          selectable: false,
          name: "product",
          id: prod.product_name,
          enc_id: prod?.enc_id,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          draft_id: prod?.product_id,
          position: prod?.position,
          initialColor: fillColor,
          color: prod?.product_color,
          item_type: prod?.type,
        });
        if (
          from?.from_draft_id == prod?.product_id ||
          to?.to_draft_id == prod?.product_id
        ) {
          canvas.add(obj).renderAll();
          canvas.bringToFront(obj);
        }
      }
    );
  });
};

const renderAmenities = (amenitys, canvas, projectSettings, clickhandler) => {
  amenitys.forEach((item, idx) => {
    // console.log(item,'renderAmenities')
    let fillColor = item?.amenity_color ?? projectSettings?.amenity_color;
    let amenityIcon = ChangeSvgColorPassingBE(item?.path, fillColor);

    let path = fabric.loadSVGFromString(
      amenityIcon,
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);

        obj.set({
          left: item.position?.x - obj.width / 2,
          top: item.position?.y - obj.height / 2,
          selectable: false,
          name: "amenity",
          id: item.amenity_name,
          enc_id: item?.enc_id,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          position: item?.position,
          initialColor: fillColor,
          icon_path: item?.path,
          icon_color: fillColor,
          draft_id: item?.amenity_id,
          color: item?.amenity_color,
          item_type: item?.type,
        });
        canvas.add(obj).renderAll();
        canvas.bringToFront(obj);
        obj.on("mousedown", (event) => {
          // if (event.touches.length === 1) {
            // clickhandler(event,item);
          // }
        });
      }
    );
  });
};

const renderSafeties = (safeties, canvas, projectSettings, clickhandler) => {
  safeties.forEach((item, idx) => {
    let fillColor = item?.safety_color ?? projectSettings?.safety_color;
    let safetyIcon = ChangeSvgColorPassingBE(item?.path, fillColor);

    let path = fabric.loadSVGFromString(
      safetyIcon,
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);

        obj.set({
          left: item.position?.x - obj.width / 2,
          top: item.position?.y - obj.height / 2,
          selectable: false,
          name: "safety",
          id: item.safety_name,
          enc_id: item?.enc_id,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          position: item?.position,
          draft_id: item?.safety_id,
          icon_path: item?.path,
          initialColor: fillColor,
          color: item?.safety_color,
          icon_color: fillColor,
          item_type: item?.type,
        });

        canvas.add(obj).renderAll();
        canvas.bringToFront(obj);
        obj.on("mousedown", (event) => {
          // if (event.touches.length === 1) {
            // clickhandler(event,item);
          // }
        });
      }
    );
  });
};

const renderVerticalTransport = (vercalTransports, canvas, projectSettings) => {
  vercalTransports.forEach((item, idx) => {
    let fillColor = item?.vt_color ?? projectSettings?.level_change_color;
    let safetyIcon = ChangeSvgColorPassingBE(item?.path, fillColor);
    let path = fabric.loadSVGFromString(
      safetyIcon,
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);
        obj.set({
          left: item.position?.x - obj.width / 2,
          top: item.position?.y - obj.height / 2,
          selectable: false,
          name: "vertical",
          id: item.vt_name,
          enc_id: item?.enc_id,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          position: item?.position,
          draft_id: item?.vtd_id,
          icon_path: item?.path,
          initialColor: fillColor,
          color: item?.vt_color,
          icon_color: fillColor,
          ignoreZoom: true,
          item_type:item?.type
        });
        canvas.add(obj).renderAll();
        canvas.bringToFront(obj);
      }
    );
  });
};

export {
  renderTracings,
  renderTexts,
  renderBeacons,
  renderLocations,
  renderProducts,
  renderAmenities,
  renderSafeties,
  renderVerticalTransport,
  renderTracingCircle,
};
